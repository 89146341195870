<script setup lang="ts">
const drawer = ref()

const { data: blogNav } = await useAsyncData("navigation", () => {
  return fetchContentNavigation(queryContent("docs"));
});


</script>

<template>
  <v-card>
    <v-navigation-drawer v-model="drawer"
                         permanent>
      <v-list nav>


        <v-list-item v-for="item in blogNav[0].children"
                     :key="item._path"
                     :title="item.title"
                     :to="item._path">
          <!-- <v-tooltip activator="parent"
                     location="end">{{ item.title }}</v-tooltip> -->
        </v-list-item>
      </v-list>

    </v-navigation-drawer>
  </v-card>
</template>
