<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useDisplay } from "vuetify";
import { useLeftFormMenu } from "~/composables/useLeftFormMenu";

const { mobile } = useDisplay();
const { isAppDrawerVisible, rightDrawer } = storeToRefs(useUIStore());
const { leftFormMenu } = useLeftFormMenu()
</script>

<template>
  <div>
    <v-app>
      <NavAppHeader />
      <NavAppDrawerContent />
      <NavAppRightDrawer v-if="rightDrawer" />
      <NavAppDrawerFormMenu v-if="!mobile && leftFormMenu" />
      <v-main>
        <v-container>
          <CommonAlert />
          <slot />
        </v-container>
      </v-main>
    </v-app>
  </div>
</template>
